import React, { useState, useEffect } from "react";
import "./App.css";
import { isLogged } from "./App";
import { supabase } from "./App";
import swal from "sweetalert";

const GameResult = ({ id, sport, athlet1, athlet2, date, games }) => {
  const [edit, EditMode] = useState(false);
  const [availableGames, setAvailableGames] = useState([]);
  const [availableDates, setAvailableDates] = useState([]);
  const [selectedGame, setSelectedGame] = useState(sport);
  const [selectedDate, setSelectedDate] = useState(date);

  async function DeleteEntry() {
    try {
      const error = await supabase
        .from("Game_Results")
        .delete()
        .eq("id", id);
      if (error) {
        if (error.status === 204) {
          window.location.reload();
        } else {
          console.log(error);
        }
      }
      else{
        window.location.reload();
      }
    } catch (e) {
      console.log(e);
    }
  }

  async function EditGameResult() {
    const firstscore = document.getElementById("game_resultfirstathlet");
    const secondscore = document.getElementById("game_resultsecondathlet");

    if (
      selectedGame !== "" &&
      selectedDate !== "" &&
      firstscore !== "" &&
      secondscore !== ""
    ) {
      try {
        const gid = games.find(
          (game) =>
            game.game_date === selectedDate && game.Sport === selectedGame
        );
        console.log(gid, selectedDate, selectedGame, games);
        const { error } = await supabase
          .from("Game_Results")
          .update({
            athlet1_score: firstscore.value,
            athlet2_score: secondscore.value,
            game_id: gid.id,
          })
          .eq("id", id);

        if (error) console.log(error);

        EditMode(false);

        window.location.reload();
      } catch (e) {
        console.log(e);
        swal("Ошибка!", "Не удалось сохранить изменения.", "error");
      }
    }
  }

  useEffect(() => {
    setAvailableGames([...new Set(games.map((game) => game.Sport))]);
    setAvailableDates([
      ...new Set(
        games
          .filter((game) => game.Sport === selectedGame)
          .map((game) => game.game_date)
      ),
    ]);
  }, [selectedGame, games]);

  return (
    <div className="card">
      {edit ? (
        <>
          <h2 className="tittle">
            <strong>Название спорта:</strong>{" "}
            <select
              id="game_resultgameName"
              value={selectedGame}
              onChange={(e) => setSelectedGame(e.target.value)}
            >
              <option value="">Выберите спорт</option>
              {availableGames.map((game) => (
                <option key={game} value={game}>
                  {game}
                </option>
              ))}
            </select>
          </h2>
          <p className="info">
            <strong>Счёт первого спортсмена:</strong>{" "}
            <input
              id="game_resultfirstathlet"
              type="number"
              defaultValue={athlet1}
            />
          </p>
          <p className="info">
            <strong>Счёт второго спортсмена:</strong>{" "}
            <input
              id="game_resultsecondathlet"
              type="number"
              defaultValue={athlet2}
            />
          </p>
          <p className="info">
            <strong>Дата:</strong>{" "}
            <select
              id="game_resultgameDate"
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
            >
              <option value="">Выберите дату</option>
              {availableDates.map((date) => (
                <option key={date} value={date}>
                  {date}
                </option>
              ))}
            </select>
          </p>
          <div className="card_button">
            <button className="supp_button" onClick={EditGameResult}>
              Сохранить
            </button>
            <button className="supp_button" onClick={() => EditMode(false)}>
              Отменить
            </button>
          </div>
        </>
      ) : (
        <>
          <h2 className="tittle">{sport}</h2>
          <p className="info">
            <strong>Счёт первого спортсмена:</strong> {athlet1} очков
          </p>
          <p className="info">
            <strong>Счёт второго спортсмена:</strong> {athlet2} очков
          </p>
          <p className="info">
            <strong>Дата игры:</strong> {date}
          </p>
          {isLogged && (
            <div className="card_button">
              <button className="supp_button" onClick={() => EditMode(true)}>
                Изменить
              </button>
              <button className="supp_button" onClick={DeleteEntry}>
                Удалить
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default GameResult;